import React, { useEffect } from 'react';
import Layout from '../components/layout';
import { navigate } from 'gatsby';

const RoadmapLoader = ({}) => {
  useEffect(() => {
    setTimeout(() => {
      navigate('/dashboard/#roadmap');
    }, 4500);
  });

  return (
    <Layout className='loader'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-3' />
          <div className='col-sm-6'>
            <div className='box-loader text-center'>
              <div className='generate'>
                Generating your roadmap <br />
                based on the answers you've given ...
              </div>
              <div className='loading'>
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RoadmapLoader;
